<template>
	<div class="analysis-page" v-loading="loading">
		<div class="search-box">
			<div class="con-title">基本信息</div>
			<el-form class="form-content" label-width="120px">
				<div class="inline">
					<el-form-item label="性别">
						<el-radio-group v-model="sex">
							<el-radio label="" disabled>不限</el-radio>
							<el-radio :label="1" disabled>男</el-radio>
							<el-radio :label="2" disabled>女</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="年龄">
						<div style="display: flex; align-items: center">
							<el-input placeholder="最小值" v-model="ageLowerLimit" type="number" style="width: 150px"
								disabled class="custom-input"></el-input>&nbsp;岁
							<span class="divider-line"></span>
							<el-input placeholder="最大值" v-model="ageUpperLimit" type="number" style="width: 150px"
								disabled class="custom-input"></el-input>&nbsp;岁
						</div>
					</el-form-item>
				</div>
				<div class="inline">
					<el-form-item label="主要诊断">
						<el-select v-model="mainDiagnosis" filterable clearable="" placeholder="请选择"
							style="width: 350px" disabled>
							<el-option v-for="item in typeArr" :label="item.name" :value="item.value"
								:key="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="其他诊断">
						<el-select v-model="otherDiagnosis" multiple="" filterable clearable="" placeholder="请选择"
							style="width: 350px" disabled>
							<el-option v-for="item in typeArr" :label="item.name" :value="item.value"
								:key="item.value"></el-option>
						</el-select>
					</el-form-item>
				</div>
				<div class="inline">
					<el-form-item label="确诊时间">
						<el-date-picker v-model="dateTime" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 350px" clearable
							disabled></el-date-picker>
					</el-form-item>
					<el-form-item label="所在地区">
						<city-multi-cascader v-model="cityInfo" :myData="cityInfo" style="width: 350px"
							:disabled="true"></city-multi-cascader>
						<!-- <el-select
              v-model="otherDiagnosis"
              multiple=""
              filterable
              clearable=""
              placeholder="请选择"
              style="width: 350px"
              disabled
            >
            </el-select> -->
					</el-form-item>
				</div>
			</el-form>
		</div>
		<div class="con-title">问卷量表</div>
		<div class="patient-analysis">
			<div class="left">
				<div class="out-container" :class="[
            { 'out-container-line marginLeft': treeList.length > 1 },
            { 'out-container__active': firstIndex == index },
          ]" v-for="(item, index) in treeList" :key="index" @click="clickCurrentContainer(item, index)">
					<div class="out-container-cover" v-if="index == treeList.length - 1"></div>
					<div class="out-relationship-select" v-if="index < treeList.length - 1">
						<el-select style="width: 60px" v-model="item.selectOutRelation" disabled>
							<el-option label="与" :value="1"></el-option>
							<el-option label="或" :value="2"></el-option>
						</el-select>
					</div>
					<div class="out-container-body">
						<span class="txt-cut" style="-webkit-box-orient: vertical; padding-right: 10px"
							:title="item.questionName">{{ item.questionName ? item.questionName : "未命名" }}</span>
						<span v-if="index > 0" class="delete-btn" @click.stop="deleteOutContainer(item, index)">
							<i class="el-icon-delete"></i>
						</span>
					</div>
				</div>
				<!-- <div
          class="out-container"
          :class="{ marginLeft: treeList.length > 1 }"
          style="border-top: none"
          @click="addOutContainer"
        >
          <div class="out-container-body">
            <span class="add-item">+新增问卷条件</span>
          </div>
        </div> -->
			</div>
			<div class="right">
				<div class="condition-filter">
					<div class="custom-analysis-filter">
						<div class="single-analysis">
							<!-- <el-button type="primary" @click="showchoseQuestionDialog"
                >选择问卷</el-button
              > -->
							<!-- <span>{{questionObj.questionName}}</span> -->
							<el-button type="text" @click="toDataCollectInfo">预览问卷</el-button>
						</div>
						<div class="out-container2" :class="{ 'out-container-line': analysisList.length > 1 }"
							v-for="(secondItem, $index) in analysisList" :key="$index">
							<div class="out-container-cover" v-if="$index == analysisList.length - 1"></div>
							<div class="in-relationship-select" v-if="$index < analysisList.length - 1">
								<el-select style="width: 60px" v-model="secondItem.select" disabled>
									<el-option label="与" :value="1"></el-option>
									<el-option label="或" :value="2"></el-option>
								</el-select>
							</div>
							<div class="in-relationship-list">
								<div class="in-container-wrapper">
									<div class="in-container"
										:class="{ 'in-container-line': secondItem.list.length > 1 }"
										v-for="(ele, i) in secondItem.list" :key="i">
										<div class="in-container-cover" v-if="i == secondItem.list.length - 1"></div>
										<div class="in-relationship-select" v-if="i < secondItem.list.length - 1">
											<el-select style="width: 60px" v-model="ele.require" disabled>
												<el-option label="与" :value="1"></el-option>
												<el-option label="或" :value="2"></el-option>
											</el-select>
										</div>
										<div class="in-container-item">
											<div class="item-con width30">
												<el-select class="width100" placeholder="请选择问卷题目"
													v-model="ele.questionInfo" value-key="id" clearable filterable
													@change="
                            (val) => {
                              changeQuestionInfo(val, $index, i, ele);
                            }
                          " disabled>
													<el-option v-for="(item, index) in questionTitleList" :key="index"
														:value="item"
														:label="item.options.label">{{ item.options.label }}</el-option>
												</el-select>
											</div>
											<div class="item-con width20">
												<el-select class="width100" placeholder="请选择维度" v-model="ele.type"
													clearable @change="
                            (val) => {
                              changeQuestionType(val, $index, i, ele);
                            }
                          " disabled>
													<el-option v-for="(wd, index) in ele.wdList" :key="index"
														:value="wd.value" :label="wd.name">{{ wd.name }}</el-option>
												</el-select>
											</div>
											<!-- 数值 -->
											<div v-if="
                          ele.type == 1 &&
                          (ele.questionInfo.type == 'input' ||
                            ele.questionInfo.type == 'number' ||
                            ele.questionInfo.type == 'slider' ||
                            ele.questionInfo.type == 'rate')
                        " class="item-con" style="width: 40%">
												<el-select placeholder="请选择比较方式" v-model="ele.sz" clearable
													style="width: 39%; margin-right: 10px" @change="
                            (val) => {
                              changeQuestionSz(val, ele);
                            }
                          " disabled>
													<el-option v-for="(sz, index) in szList" :key="index"
														:value="sz.value" :label="sz.name">{{ sz.name }}</el-option>
												</el-select>
												<div style="width: 56%" v-if="ele.sz == 6" class="item-con">
													<el-input style="width: 50%; margin-right: 10px"
														placeholder="请输入下限值" v-model="ele.began" :min="0" type="number"
														disabled></el-input>
													<el-input placeholder="请输入上限值" v-model="ele.end" :min="ele.began"
														type="number" style="width: 50%" disabled></el-input>
												</div>
												<el-input v-if="ele.sz && ele.sz != 6" placeholder="请输入数值"
													v-model="ele.content" style="width: 56%" min="0" type="number"
													disabled></el-input>
											</div>
											<!-- 内容 -->
											<div class="item-con" style="width: 40%" v-if="
                          ele.type == 2 &&
                          ele.questionInfo &&
                          (ele.questionInfo.type == 'input' ||
                            ele.questionInfo.type == 'textarea' ||
                            ele.questionInfo.type == 'rich-editor')
                        ">
												<!--  -->
												<el-select style="width: 39%; margin-right: 10px" placeholder="请选择是否包含"
													v-model="ele.sz" clearable disabled>
													<el-option :value="7" label="内容含">包含</el-option>
													<el-option :value="8" label="内容不含"></el-option>
												</el-select>
												<el-input v-if="ele.sz" placeholder="请输入关键字" v-model="ele.content"
													style="width: 56%" disabled></el-input>
											</div>
											<!-- 时间 -->
											<div class="item-con" style="width: 40%" v-if="
                          ele.type == 1 &&
                          ele.questionInfo &&
                          (ele.questionInfo.type == 'time-range' ||
                            ele.questionInfo.type == 'time')
                        ">
												<el-select placeholder="请选择比较方式" v-model="ele.sz" clearable
													style="width: 39%; margin-right: 10px" v-if="
                            ele.questionInfo && ele.questionInfo.type == 'time'
                          " @change="
                            (val) => {
                              changeQuestionSz(val, ele);
                            }
                          " disabled>
													<el-option v-for="(sz, index) in sjList" :key="index"
														:value="sz.value" :label="sz.name">{{ sz.name }}</el-option>
												</el-select>
												<el-select placeholder="请选择比较方式" v-model="ele.sz" clearable
													style="width: 39%; margin-right: 10px" v-if="
                            ele.questionInfo &&
                            ele.questionInfo.type == 'time-range'
                          " disabled>
													<el-option v-for="(sz, index) in sjList2" :key="index"
														:value="sz.value" :label="sz.name">{{ sz.name }}</el-option>
												</el-select>
												<el-time-picker style="width: 56%" v-if="
                            ele.sz &&
                            ele.sz != 6 &&
                            ele.questionInfo.type == 'time'
                          " v-model="ele.content" placeholder="请选择时间" clearable value-format="HH:mm:ss" disabled>
												</el-time-picker>
												<!--类型为单个时间条件为介于则为时间框 -->
												<el-time-picker v-show="
                            ele.sz &&
                            ele.questionInfo &&
                            (ele.questionInfo.type == 'time-range' ||
                              (ele.questionInfo.type == 'time' && ele.sz == 6))
                          " is-range v-model="ele.content" range-separator="至" start-placeholder="开始时间"
													end-placeholder="结束时间" placeholder="选择时间范围" style="width: 56%"
													value-format="HH:mm:ss" disabled>
												</el-time-picker>
											</div>
											<!-- 日期 -->
											<div class="item-con" style="width: 40%" v-if="
                          ele.type == 1 &&
                          ele.questionInfo &&
                          (ele.questionInfo.type == 'date' ||
                            ele.questionInfo.type == 'date-range')
                        ">
												<el-select placeholder="请选择比较方式" v-model="ele.sz" clearable
													style="width: 39%; margin-right: 10px"
													v-if="ele.questionInfo.type == 'date'" @change="
                            (val) => {
                              changeQuestionSz(val, ele);
                            }
                          " disabled>
													<el-option v-for="(sz, index) in sjList" :key="index"
														:value="sz.value" :label="sz.name">{{ sz.name }}</el-option>
												</el-select>
												<el-select placeholder="请选择比较方式" v-model="ele.sz" clearable
													style="width: 39%; margin-right: 10px"
													v-if="ele.questionInfo.type == 'date-range'" disabled>
													<el-option v-for="(sz, index) in sjList2" :key="index"
														:value="sz.value" :label="sz.name">{{ sz.name }}</el-option>
												</el-select>
												<el-date-picker v-if="
                            ele.sz &&
                            ele.sz != 6 &&
                            ele.questionInfo.type == 'date'
                          " v-model="ele.content" type="date" placeholder="选择日期" style="width: 56%" disabled>
												</el-date-picker>
												<el-date-picker style="width: 56%" v-show="
                            ele.sz &&
                            (ele.questionInfo.type == 'date-range' ||
                              (ele.questionInfo.type == 'date' && ele.sz == 6))
                          " v-model="ele.content" type="daterange" range-separator="至" start-placeholder="开始日期"
													end-placeholder="结束日期" value-format="yyyy-MM-dd" disabled>
												</el-date-picker>
											</div>
											<!-- 下拉select -->
											<div class="item-con" style="width: 40%" v-if="
                          ele.type == 3 &&
                          ele.questionInfo &&
                          ele.questionInfo.type == 'select'
                        ">
												<el-select style="width: 39%; margin-right: 10px" placeholder="请选择是否包含"
													v-model="ele.sz" clearable disabled>
													<el-option :value="11" label="选项为"></el-option>
													<el-option :value="12" label="选项不为"></el-option>
												</el-select>
												<el-select style="width: 56%; margin-right: 10px" placeholder="请选择选项"
													v-model="ele.content" clearable multiple="" v-if="
                            ele.questionInfo &&
                            ele.questionInfo.options &&
                            ele.questionInfo.options.optionItems &&
                            ele.questionInfo.options.optionItems.length
                          " disabled>
													<el-option :value="element.value" :label="element.label" v-for="(element, i) in ele.questionInfo.options
                              .optionItems" :key="i">{{ element.label }}</el-option>
												</el-select>
											</div>
											<!-- 单选 -->
											<div class="item-con" style="width: 40%" v-if="
                          ele.type == 3 &&
                          ele.questionInfo &&
                          ele.questionInfo.type == 'radio'
                        ">
												<el-select style="width: 39%; margin-right: 10px" placeholder="请选择是否包含"
													v-model="ele.sz" clearable disabled>
													<el-option :value="11" label="选项为"></el-option>
													<el-option :value="12" label="选项不为"></el-option>
												</el-select>
												<el-select style="width: 56%; margin-right: 10px" placeholder="请选择选项"
													v-model="ele.content" clearable v-if="
                            ele.questionInfo &&
                            ele.questionInfo.options &&
                            ele.questionInfo.options.optionItems &&
                            ele.questionInfo.options.optionItems.length
                          " disabled>
													<el-option :value="element.value" :label="element.label" v-for="(element, i) in ele.questionInfo.options
                              .optionItems" :key="i">{{ element.label }}</el-option>
												</el-select>
											</div>
											<!-- 多选 -->
											<div class="item-con" style="width: 40%" v-if="
                          ele.type == 3 &&
                          ele.questionInfo &&
                          ele.questionInfo.type == 'checkbox'
                        ">
												<el-select style="width: 39%; margin-right: 10px" placeholder="请选择是否包含"
													v-model="ele.sz" clearable disabled>
													<el-option :value="11" label="选项为"></el-option>
													<el-option :value="12" label="选项不为"></el-option>
												</el-select>
												<el-select style="width: 56%; margin-right: 10px" placeholder="请选择选项"
													v-model="ele.content" clearable multiple="" v-if="
                            ele.questionInfo &&
                            ele.questionInfo.options &&
                            ele.questionInfo.options.optionItems &&
                            ele.questionInfo.options.optionItems.length
                          " disabled>
													<el-option :value="element.value" :label="element.label" v-for="(element, i) in ele.questionInfo.options
                              .optionItems" :key="i">{{ element.label }}</el-option>
												</el-select>
											</div>
											<!-- <div
                        class="item-con"
                        style="width: 40%"
                        v-if="
                          ele.type == 2 &&
                          ele.questionInfo &&
                          ele.questionInfo.indexType == 7
                        "
                      >
                        <el-select
                          class="width100"
                          style="margin-right: 10px"
                          placeholder="请选择选项"
                          v-model="ele.content"
                          clearable
                          multiple
                        >
                          <el-option
                            :value="element.optionName"
                            v-for="(element, i) in ele.questionInfo.remark"
                            :key="i"
                            >{{ element.optionName }}</el-option
                          >
                        </el-select>
                      </div> -->
											<!-- <div>
                        <span
                          class="delete-btn"
                          @click="deleteSecondContainer($index)"
                          v-if="secondItem.list.length == 1"
                          >删除</span
                        >
                        <span
                          class="delete-btn"
                          @click="deleteInContainer($index, i)"
                          v-else
                          >删除</span
                        >
                      </div> -->
										</div>
									</div>
									<!-- 新增筛选条件 -->
									<!-- <div class="add-btn" @click="addThirdContainer($index)">
                    + 增加筛选条件
                  </div> -->
								</div>
							</div>
						</div>
						<!-- <div class="add-btn-type2" @click="addOutContainer2">+ 增加组合条件</div> -->
					</div>
				</div>
			</div>
		</div>
		<div class="con-title" style="padding-top: 24px; margin-top: 24px">
			导出字段
		</div>
		<el-table :data="dataList">
			<el-table-column label="序号" type="index" width="100"></el-table-column>
			<el-table-column label="条件" width="300" prop="questionName"></el-table-column>
			<el-table-column label="导出字段">
				<template slot-scope="scope">
					<div class="export-string">
						<div class="choose-list">
							<div class="list-item" v-for="(item, index) in scope.row.currentChoseList" :key="index">
								<el-tooltip class="item" effect="dark" :content="item.options.label"
									placement="top-start">
									<div class="con-box">
										<div class="text_break" style="color: #0099ff">
											{{ item.options.label }}
										</div>
										<!-- <span
                      class="delete-btn"
                      @click="deleteCurrentChoseList(scope.row, index)"
                    ></span> -->
									</div>
								</el-tooltip>
							</div>
						</div>
						<div v-if="
                scope.row.questionTitleList &&
                scope.row.questionTitleList.length
              ">
							<el-select v-model="scope.row.choseList" value-key="id" multiple="" collapse-tags
								clearable="" filterable="" style="margin-right: 8px" disabled>
								<el-option v-for="(item, index) in scope.row.questionTitleList" :key="index"
									:value="item" :label="item.options.label"></el-option>
							</el-select>
						</div>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<!-- 选择问卷 -->
		<chose-question v-if="choseQuestionDialog" ref="choseQuestion" @setQuestionId="setQuestionId"
			:treeList="treeList"></chose-question>
		<!-- 筛查结果 -->
		<screening-result v-if="screeningResultDialog" ref="screeningResult"></screening-result>
	</div>
</template>

<script>
	import ChoseQuestion from "./components/choseQuestion";
	import ScreeningResult from "./components/screeningResult";
	import CityMultiCascader from "@/components/cascader/cityCascader";
	import Qs from "qs";
	export default {
		components: {
			ChoseQuestion,
			ScreeningResult,
			CityMultiCascader,
		},
		data() {
			return {
				id: "",
				sex: "", //年龄
				ageLowerLimit: "", //年龄下限
				ageUpperLimit: "", //年龄上限
				typeArr: [], //主要诊断
				mainDiagnosis: "", //搜索的主要诊断内容
				otherDiagnosis: [], //其他诊断
				cityInfo: {}, //所在地区
				// 问卷名称
				questionObj: "",
				selectDateType: 1, //日期类型
				dateTime: "", //具体日期
				dateTime2: "",
				treeList: [{
					selectOutRelation: 1,
					questionName: "",
					analysisList: [{
						list: [{
							require: 1,
							type: "",
							sz: "",
							content: "",
							questionInfo: {},
						}, ],
					}, ],
				}, ],
				analysisList: [{
					list: [{
						require: 1,
						type: "",
						sz: "",
						content: "",
						questionInfo: {},
					}, ],
				}, ],
				currentLength: 1,
				firstIndex: 0, //点击新增左侧
				secondIndex: 0, //点击右侧新增白色边框遮住
				thirdIndex: 0, //点击新增筛选条件出现边框遮线
				choseQuestionDialog: false,
				screeningResultDialog: false, //筛查结果

				questionTitleList: [], //问卷题目
				wdList: [{
						value: 1,
						name: "数值"
					},
					{
						value: 2,
						name: "内容"
					},
					{
						value: 3,
						name: "选项"
					},
				], //维度列表
				szList: [{
						value: 1,
						name: "大于"
					},
					{
						value: 2,
						name: "大于等于"
					},
					{
						value: 3,
						name: "等于"
					},
					{
						value: 4,
						name: "小于"
					},
					{
						value: 5,
						name: "小于等于"
					},
					{
						value: 6,
						name: "介于"
					},
				], //数值列表
				sjList: [{
						value: 2,
						name: "早于"
					},
					{
						value: 5,
						name: "晚于"
					},
					{
						value: 3,
						name: "等于"
					},
					{
						value: 6,
						name: "介于"
					},
				], //时间列表
				sjList2: [{
					value: 6,
					name: "介于"
				}], //时间列表
				info: {},
				dataList: [{
					questionNameId: 1,
					questionName: "基本信息",
					questionTitleList: [{
							id: 11,
							options: {
								label: "病例ID"
							},
						},
						// {
						//   id: 1,
						//   options: { label: "姓名" },
						// },
						{
							id: 2,
							options: {
								label: "证件类型"
							},
						},
						// {
						//   id: 3,
						//   options: { label: "证件号" },
						// },
						// {
						//   id: 4,
						//   options: { label: "联系方式" },
						// },
						{
							id: 5,
							options: {
								label: "主要诊断"
							},
						},
						{
							id: 6,
							options: {
								label: "其他诊断"
							},
						},
						{
							id: 7,
							options: {
								label: "确诊时间"
							},
						},
						{
							id: 8,
							options: {
								label: "责任医生"
							},
						},
						{
							id: 9,
							options: {
								label: "备注"
							},
						},
						{
							id: 10,
							options: {
								label: "家属联系方式"
							},
						},
						{
							id: 12,
							options: {
								label: "家属联系方式2"
							},
						},
						{
							id: 13,
							options: {
								label: "所在地区"
							},
						},
						{
							id: 14,
							options: {
								label: "详细地址"
							},
						},
					],
				}, ],
				loading: false,
			};
		},
		mounted() {
			this.getPorjType();
			if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.getList();
			}
		},
		methods: {
			async getList() {
				let _this = this;
				_this.loading = true;
				const {
					data: res
				} = await this.$httpAes({
        url: this.$http.adornUrl("/mbglLabelIndex/list"),
					method: "post",
					data: {
						id: _this.id
					},
				});

				if (res.status && res.data && res.data.length) {
					_this.loading = false;
					let info = res.data[0];
					this.info = res.data[0];
					_this.sex = info.sex;
					_this.ageLowerLimit = info.ageLowerLimit;
					_this.ageUpperLimit = info.ageUpperLimit;
					_this.mainDiagnosis = info.mainDiagnosis;
					let list = info.otherDiagnosis ? info.otherDiagnosis.split(",") : "";
					if (list.length) {
						_this.otherDiagnosis = list.map((item) => {
							return item * 1;
						});
					}
					if (info.diagnosisEndTime) {
						_this.dateTime = [info.diagnosisStartTime, info.diagnosisEndTime];
					}
					_this.cityInfo = {
						province: info.patientProvince,
						provinceName: info.provinceName,
						city: info.patientCity,
						cityName: info.cityName,
						block: info.patientCounty,
						blockName: info.countyName,
					};
					if (info.content) {
						_this.treeList = [];
						let content = JSON.parse(info.content);
						content.forEach((ele, index) => {
							let obj = {
								questionNameId: ele.infoId,
								questionName: ele.infoName,
								selectOutRelation: ele.require,
							};
							obj.analysisList = [{
								list: [],
							}, ];
							obj.analysisList[0].list = ele.data;
							_this.treeList.push(obj);
							if (_this.treeList.length) {
								_this.questionObj = {
									questionNameId: _this.treeList[0].questionNameId,
									questionName: _this.treeList[0].questionName,
								};
								_this.getQustionTitleList();
								_this.analysisList = _this.treeList[0].analysisList;
								console.log("456", _this.analysisList);
								if (_this.analysisList.length) {
									_this.analysisList[0].list.forEach((element) => {
										if (
											element.content &&
											(element.questionType == "time-range" ||
												element.questionType == "date-range")
										) {
											element.content = element.content.split(",");
										}
									});
								}
							}
						});
					} else {}
				} else {
					_this.loading = false;
				}

				if (_this.treeList.length) {
					let arr = [];
					_this.treeList.forEach((ele) => {
						if (ele.questionNameId) {
							arr.push(ele);
						}
					});

					_this.dataList = [
						_this.dataList[0],
						...JSON.parse(JSON.stringify(arr)),
					];

					_this.dataList.forEach((ele, index) => {
						this.$set(ele, 'currentChoseList', [])
						this.$set(ele, 'choseList', [])
						// ele.currentChoseList = [];
						// ele.choseList = [];
						if (ele.questionNameId) {
							let params = {
								id: ele.questionNameId,
								stringParam1: ele.questionNameId,
							};
							if (params.id) {
								_this
									.$httpAes({
										url: _this.$http.adornUrl("/vfTableInfo/info"),
										method: "post",
										data: params,
									})
									.then(({
										data
									}) => {
										if (data.status && data.data && data.data.tableContent) {
											let widgetList = JSON.parse(
												data.data.tableContent
											).widgetList;
											let arr = [];
											if (widgetList.length) {
												widgetList.forEach((ele) => {
													if (
														ele.type == "input" ||
														ele.type == "slider" ||
														ele.type == "radio" ||
														ele.type == "checkbox" ||
														ele.type == "number" ||
														ele.type == "textarea" ||
														ele.type == "time" ||
														ele.type == "time-range" ||
														ele.type == "date" ||
														ele.type == "date-range" ||
														ele.type == "select" ||
														ele.type == "rate" ||
														ele.type == "rich-editor"
													) {
														arr.push(ele);
													}
												});
											}
											this.$set(ele, "questionTitleList", arr);
											// ele.questionTitleList = widgetList;
										}
									});
								if (_this.info.exportedContent) {
									let exportedContent = JSON.parse(_this.info.exportedContent);
									exportedContent.forEach((chr) => {
										if (ele.questionNameId == chr.questionNameId) {
											if (chr.currentChoseList && chr.currentChoseList.length) {
												// this.$set(ele, "currentChoseList", chr.currentChoseList);
												// this.$set(ele, "choseList", chr.choseList);
												ele.currentChoseList = chr.currentChoseList;
												ele.choseList = chr.choseList;
											}
										}
									});
								}
							} else {}
						}
					});
					// setTimeout(()=>{

					// },500)
					// if (_this.dataList.length>1) {
					//   _this.dataList = JSON.parse(JSON.stringify(_this.dataList));
					// }
					console.log("_this.dataList", _this.dataList);
				}
			},

			// 获取主要诊断列表
			getPorjType() {
				this.dataListLoading = true;
				this.$httpAes({
					url: this.$http.adornUrl("/dict/list?type=主要诊断"),
					method: "post",
					data: {
					  stringParam1: "主要诊断",
					},
				}).then(({
					data
				}) => {
					this.typeArr = data.data;
				});
			},
			// 预览问卷
			toDataCollectInfo() {
				let _this = this;
				if (!_this.questionObj.questionNameId) {
					_this.$message.error("请选择问卷名称");
					return;
				}
				const href = this.$router.resolve({
					name: "preview",
					query: {
						id: _this.questionObj.questionNameId
					},
				});
				window.open(href.href);
			},
			//点击当前的未命名问卷
			clickCurrentContainer(item, index) {
				let _this = this;
				_this.firstIndex = index;
				_this.analysisList = _this.treeList[index].analysisList;
				// _this.secondIndex = _this.analysisList.length - 1;
				// _this.analysisList[0].list.push({
				//   require: 1,
				//   type: "",
				//   sz: "",
				//   content: "",
				//   questionInfo: {},
				// });

				if (item && item.questionNameId) {
					_this.questionObj.questionNameId = item.questionNameId;
					_this.getQustionTitleList(item);
				} else {
					_this.questionTitleList = [];
				}
			},
			//   添加未命名
			addOutContainer(index) {
				let _this = this;
				_this.treeList.push({
					selectOutRelation: 1,
					analysisList: [{
						list: [],
					}, ],
				});
				_this.firstIndex = _this.treeList.length - 1;
				if (_this.firstIndex - 1 == 0) {
					_this.treeList[_this.firstIndex - 1].analysisList = JSON.parse(
						JSON.stringify(_this.analysisList)
					);
				}
				_this.analysisList = _this.treeList[_this.firstIndex].analysisList;
				_this.analysisList[0].list.push({
					require: 1,
					type: "",
					sz: "",
					content: "",
					questionInfo: {},
				});

				_this.secondIndex = _this.analysisList.length - 1;
				_this.currentLength = _this.treeList.length;
				_this.questionObj = {};
				_this.getQustionTitleList();
				console.log("123", _this.treeList);
			},
			// 删除未命名
			deleteOutContainer(item, index) {
				let _this = this;
				_this.treeList.splice(index, 1);
				_this.firstIndex = _this.treeList.length - 1;
				_this.analysisList = _this.treeList[_this.firstIndex].analysisList;
				if (
					_this.treeList[_this.firstIndex] &&
					_this.treeList[_this.firstIndex].questionNameId
				) {
					_this.questionObj.questionNameId =
						_this.treeList[_this.firstIndex].questionNameId;
					_this.getQustionTitleList(item);
				} else {
					_this.questionTitleList = [];
				}
				let arr = [];
				_this.treeList.forEach((ele) => {
					if (ele.questionNameId) {
						arr.push(ele);
					}
				});

				// _this.dataList = [_this.dataList[0], ...JSON.parse(JSON.stringify(arr))];
				_this.dataList.splice(index + 1, 1);
				_this.dataList.forEach((ele, index) => {
					if (ele.questionNameId && ele.questionNameId != 1) {
						let params = {
							id: ele.questionNameId,
							stringParam1: ele.questionNameId,
						};
						if (params.id) {
							_this
								.$httpAes({
            url: _this.$http.adornUrl("/vfTableInfo/info"),
									method: "post",
									data: params,
								})
								.then(({
									data
								}) => {
									if (data.status) {
										if (data.data && data.data.tableContent) {
											let widgetList = JSON.parse(
												data.data.tableContent
											).widgetList;
											console.log("widgetList", widgetList);
											let arr = [];
											if (widgetList.length) {
												widgetList.forEach((ele) => {
													if (
														ele.type == "input" ||
														ele.type == "slider" ||
														ele.type == "radio" ||
														ele.type == "checkbox" ||
														ele.type == "number" ||
														ele.type == "textarea" ||
														ele.type == "time" ||
														ele.type == "time-range" ||
														ele.type == "date" ||
														ele.type == "date-range" ||
														ele.type == "select" ||
														ele.type == "rate" ||
														ele.type == "rich-editor"
													) {
														arr.push(ele);
													}
												});
											}
											this.$set(ele, "questionTitleList", arr);
										}
										// ele.questionTitleList = widgetList;
									}
								});
						} else {}
					}
				});
			},
			// 追加右边组合条件
			addOutContainer2() {
				let _this = this;
				_this.treeList[_this.firstIndex].analysisList.push({
					list: [{
						require: 1,
					}, ],
				});
				_this.analysisList = _this.treeList[_this.firstIndex].analysisList;
				_this.secondIndex = _this.analysisList.length - 1;
			},
			// 删除组合条件
			deleteSecondContainer(index) {
				let _this = this;
				// _this.analysisList = _this.treeList[_this.firstIndex].analysisList;
				if (_this.analysisList.length == 1) {
					_this.$message.info("已经到第一个了");
				} else {
					_this.treeList[_this.firstIndex].analysisList.splice(index, 1);
					_this.analysisList = _this.treeList[_this.firstIndex].analysisList;
					_this.secondIndex = _this.analysisList.length - 1;
				}
			},
			// 新增内部筛选条件
			addThirdContainer($index) {
				let _this = this;
				_this.analysisList[$index].list.push({
					require: 1,
					type: "",
					sz: "",
					content: "",
					questionInfo: {},
				});
				// _this.analysisList = JSON.stringify(JSON.parse(_this.analysisList));
				_this.thirdIndex = _this.analysisList[$index].list.length - 1;
				console.log("456", _this.treeList);
			},
			// 删除内部筛选条件
			deleteInContainer($index, i) {
				let _this = this;
				_this.analysisList[$index].list.splice(i, 1);
				// _this.thirdIndex =  _this.analysisList[_this.secondIndex].list.length - 1;
			},
			// 问卷题目选中情况
			changeQuestionInfo(item, pIndex, cIndex, ele) {
				// // 单行文本框可以选择数值和内容
				ele.content = "";
				ele.sz = "";
				ele.type = "";
				if (ele.questionInfo.type == "input") {
					ele.wdList = [{
							value: 1,
							name: "数值"
						},
						{
							value: 2,
							name: "内容"
						},
					];
				}
				if (
					ele.questionInfo.type == "radio" ||
					ele.questionInfo.type == "checkbox" ||
					ele.questionInfo.type == "select"
				) {
					ele.type = 3;
					ele.wdList = [{
						value: 3,
						name: "选项"
					}];
				}
				if (
					item.type == "number" ||
					item.type == "slider" ||
					item.type == "rate" ||
					item.type == "time" ||
					item.type == "time-range" ||
					item.type == "date" ||
					item.type == "date-range"
				) {
					ele.type = 1;
					ele.wdList = [{
						value: 1,
						name: "数值"
					}];
				}
				if (item.type == "textarea" || item.type == "rich-editor") {
					ele.type = 2;
					ele.wdList = [{
						value: 2,
						name: "内容"
					}];
				}
			},
			changeQuestionType(item, pIndex, cIndex, ele) {
				ele.sz = "";
				ele.content = "";
			},
			// 改变条件
			changeQuestionSz(item, ele) {
				ele.content = "";
			},
			// 重置
			resetAnalysis() {
				this.treeList = [{
					selectOutRelation: 1,
					analysisList: [{
						list: [{
							require: 1,
						}, ],
					}, ],
				}, ];
				this.analysisList = [{
					list: [{
						require: 1,
					}, ],
				}, ];
				this.sex = "";
				this.ageLowerLimit = "";
				this.ageUpperLimit = "";
				this.dateTime = [];
				this.dateTime2 = [];
			},
			// 选择问卷显示
			showchoseQuestionDialog() {
				let _this = this;
				_this.choseQuestionDialog = true;
				_this.$nextTick(() => {
					_this.$refs.choseQuestion.init(this.questionObj);
				});
			},
			//确定选中问卷
			setQuestionId(value) {
				let _this = this;
				_this.questionObj = value;
				_this.getQustionTitleList();
				_this.$set(
					_this.treeList[_this.firstIndex],
					"questionName",
					_this.questionObj.questionName
				);
				_this.$set(
					_this.treeList[_this.firstIndex],
					"questionNameId",
					_this.questionObj.questionNameId
				);
				// _this.treeList[_this.firstIndex].analysisList[0].list = [
				//   {
				//     require: 1,
				//     type: "",
				//     sz: "",
				//     content: "",
				//     questionInfo: {},
				//   },
				// ];
				let arr = [];
				_this.treeList.forEach((ele) => {
					if (ele.questionNameId) {
						// if (ele.questionNameId == value.questionNameId) {
						//   _this.$message.error('同一个问卷不能多次选择')
						// }
						arr.push(ele);
					}
				});

				// _this.dataList = [_this.dataList[0], ...JSON.parse(JSON.stringify(arr))];
				_this.dataList.push(value);
				console.log("123", this.dataList);
				_this.dataList.forEach((ele, index) => {
					if (ele.questionNameId && ele.questionNameId != 1) {
						let params = {
							id: ele.questionNameId,
							stringParam1: ele.questionNameId,
						};
						if (params.id) {
							_this
								.$httpAes({
            url: _this.$http.adornUrl("/vfTableInfo/info"),
									method: "post",
									data: params,
								})
								.then(({
									data
								}) => {
									if (data.status) {
										if (data.data && data.data.tableContent) {
											let widgetList = JSON.parse(
												data.data.tableContent
											).widgetList;
											let arr = [];
											if (widgetList.length) {
												widgetList.forEach((ele) => {
													if (
														ele.type == "input" ||
														ele.type == "slider" ||
														ele.type == "radio" ||
														ele.type == "checkbox" ||
														ele.type == "number" ||
														ele.type == "textarea" ||
														ele.type == "time" ||
														ele.type == "time-range" ||
														ele.type == "date" ||
														ele.type == "date-range" ||
														ele.type == "select" ||
														ele.type == "rate" ||
														ele.type == "rich-editor"
													) {
														arr.push(ele);
													}
												});
											}
											this.$set(ele, "questionTitleList", arr);
										}
										// ele.questionTitleList = widgetList;
									}
								});
						} else {}
					}
				});
			},
			//筛查
			showScreeningResultDialog(submitType) {
				let _this = this;
				if (!_this.questionObj.questionNameId) {
					_this.$message.error("请选择问卷名称");
					return;
				}

				let isNull = true;
				let isBig = false;
				let arr = [];
				let teamsId = "";
				let enertyId = "";
				let libraryId = [];
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
					enertyId = JSON.parse(localStorage.currentTeam).entityId;
				}
				if (_this.treeList.length == 1) {
					_this.$set(
						_this.treeList[0],
						"analysisList",
						JSON.parse(JSON.stringify(_this.analysisList))
					);
				}
				// console.log(_this.treeList);
				// return;
				_this.treeList.forEach((item, $index) => {
					libraryId.push(item.questionNameId);
					let obj = {
						infoId: item.questionNameId,
						infoName: item.questionName,
						type: 1,
						teamsId: teamsId,
						require: item.selectOutRelation,
						data: [],
					};
					item.analysisList[0].list.forEach((element, index) => {
						element.indexId = "";
						if (element.questionInfo && element.questionInfo.id) {
							element.indexId = element.questionInfo.id;
							element.questionType = element.questionInfo.type;
						}
						if (!element.indexId || !element.type) {
							isNull = false;
							return isNull;
						}
						if (element.type == 1 || element.type == 2) {
							if (!element.sz) {
								isNull = false;
								return isNull;
							} else {
								// if (element.sz) {
								//   element.end = element.began;
								// }
								if (element.sz == 6) {
									if (element.began == "" && element.end == "") {
										isNull = false;
										return isNull;
									} else {
										if (element.began > element.end) {
											isBig = true;
											return isBig;
										} else {
											element.content = element.began + "," + element.end;
										}
									}
								}
								if (
									element.content.length &&
									(element.questionType == "time-range" ||
										element.questionType == "date-range")
								) {
									element.content = element.content.join(",");
								}
							}
						}
						if (element.type == 3) {
							if (!element.content) {
								isNull = false;
								return isNull;
							}
							// if (element.questionInfo && element.questionInfo.indexType == 7) {
							//   element.content = JSON.parse(JSON.stringify(element.content));
							// }
						}
						obj.data.push(element);
					});
					arr.push(obj);
				});

				console.log("123", arr);
				// return;

				if (isNull) {
					if (isBig) {
						this.$message.error("下限值必须小于上限值");
						return;
					}
					// let newArr = JSON.parse(JSON.stringify(arr));
					// newArr.forEach((ele)=>{
					//   ele.data.forEach((chr)=>{
					//     _this.$delete(chr,"questionInfo");
					//   })
					// })
					// _this.screeningResultDialog = true;

					// let json = JSON.stringify(newArr);
					// _this.$nextTick(() => {
					//   _this.$refs.screeningResult.init(json);
					// });
					let params = this.info;
					this.$delete(params, "mbglRetrieveRecords");
					this.$delete(params, "splicingSql");
					params.content = JSON.stringify(arr);
					params.otherDiagnosis = _this.otherDiagnosis ?
						_this.otherDiagnosis.join(",") :
						"";
					(params.diagnosisStartTime = _this.dateTime.length ?
						_this.dateTime[0] :
						""),
					(params.diagnosisEndTime = _this.dateTime.length ?
						_this.dateTime[1] :
						"");

					// params = {
					//   // libraryId: libraryId.join(","),
					//   content: JSON.stringify(arr),
					//   sex: _this.sex,
					//   ageLowerLimit: _this.ageLowerLimit,
					//   ageUpperLimit: _this.ageUpperLimit,
					//   diagnosisStartTime: _this.dateTime.length ? _this.dateTime[0] : "",
					//   diagnosisEndTime: _this.dateTime.length ? _this.dateTime[1] : "",
					//   mainDiagnosis: _this.mainDiagnosis,
					//   otherDiagnosis: _this.otherDiagnosis
					//     ? _this.otherDiagnosis.join(",")
					//     : "",
					//   id: _this.id,
					// };

					if (_this.cityInfo && _this.cityInfo.provinceName) {
						params.patientProvince = _this.cityInfo.province;
						params.patientCity = _this.cityInfo.city;
						params.patientCounty = _this.cityInfo.block;
						params.provinceName = _this.cityInfo.provinceName;
						params.cityName = _this.cityInfo.cityName;
						params.countyName = _this.cityInfo.blockName;
					}
					if (submitType == 1) {
						params.status = 3;
					}
					let exportedFields = [],
						exportedContent = [];
					if (this.dataList.length) {
						this.dataList.forEach((element) => {
							let exportedContentObj = {
								questionName: element.questionName,
								questionNameId: element.questionNameId,
								choseList: element.choseList ? element.choseList : [],
								currentChoseList: element.currentChoseList ?
									element.currentChoseList :
									[],
							};
							exportedContent.push(exportedContentObj);
							element.nameArr = [];
							element.titleArr = [];
							if (element.questionName !== "基本信息") {
								if (element.currentChoseList && element.currentChoseList.length) {
									element.currentChoseList.forEach((chr) => {
										// element.nameArr.push(chr.options.label);
										element.nameArr.push(chr.id);
										element.titleArr.push(chr.options.label)
									});
								}
							} else {
								if (element.currentChoseList && element.currentChoseList.length) {
									element.currentChoseList.forEach((chr) => {
										element.nameArr.push(chr.options.label);
									});
								}
							}

							element.names = element.nameArr.join(",");
							if (element.titleArr.length) {
								element.names = element.names + ',' + element.titleArr.join(',');
							}
							if (element.names) {
								let obj = {
									[element.questionName]: element.names,
								};
								exportedFields.push(obj);
							}
						});
					}
					params.teamsId = teamsId;
					console.log(this.dataList);
					return;
					if (exportedFields.length) {
						params.exportedFields = JSON.stringify(exportedFields);
						params.exportedContent = JSON.stringify(exportedContent);
					}
					if (_this.$route.query.id) {
						params.id = _this.$route.query.id
					}

					_this
						.$httpAes({
							url: _this.$http.adornUrl("/mbglLabelIndex/update"),
							method: "post",
							data: params,
						})
						.then(({
							data
						}) => {
							if (data.status) {
								if (submitType == 1) {
									this.addQuestionSearch();
								} else {
									this.$message({
										message: data.msg,
										type: "success",
										duration: 1500,
										onClose: () => {
											this.$router.push({
												name: "caseSearch",
											});
										},
									});
								}
							} else {
								_this.$message.error(data.msg);
							}
						});
				} else {
					_this.$message.error("请完善所有条件筛选的项目");
				}
			},
			// 添加检索
			addQuestionSearch() {
				let params = {
					retrievalId: this.id
				};
				this.$httpAes({
					url: this.$http.adornUrl("/mbglRetrieveRecords/add"),
					method: "post",
					data: params,
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message({
							message: data.msg,
							type: "success",
							duration: 1500,
							onClose: () => {
								this.$router.push({
									name: "caseSearch",
								});
							},
						});
					}
				});
			},
			getQustionTitleList(item, $index) {
				let _this = this;
				let params = {
					// pageSize: 10000,
					// pageNo: 1,
					// libraryId: _this.questionObj.questionNameId,
					id: _this.questionObj.questionNameId,
					stringParam1:  _this.questionObj.questionNameId,
				};
				if (item && item.questionId) {
					params.libraryId = item.questionId;
				}
				if (params.id && params.id != 1) {
					_this
						.$httpAes({
            url: _this.$http.adornUrl("/vfTableInfo/info"),
							method: "post",
							data: params,
						})
						.then(({
							data
						}) => {
							if (data.status) {
								let widgetList = JSON.parse(data.data.tableContent).widgetList;
								let arr = [];
								if (widgetList.length) {
									widgetList.forEach((ele) => {
										if (
											ele.type == "input" ||
											ele.type == "slider" ||
											ele.type == "radio" ||
											ele.type == "checkbox" ||
											ele.type == "number" ||
											ele.type == "textarea" ||
											ele.type == "time" ||
											ele.type == "time-range" ||
											ele.type == "date" ||
											ele.type == "date-range" ||
											ele.type == "select" ||
											ele.type == "rate" ||
											ele.type == "rich-editor"
										) {
											arr.push(ele);
										}
									});
								}
								_this.questionTitleList = arr;
							}
						});
				} else {
					_this.questionTitleList = [];
				}
			},
			// 确定当前选中的题目列表
			confirmCurrentChoseList(row, index) {
				if (row.choseList.length) {
					this.$set(
						row,
						"currentChoseList",
						JSON.parse(JSON.stringify(row.choseList))
					);
				} else {
					this.$set(row, "currentChoseList", []);
				}
				console.log(this.dataList);
				this.dataList = JSON.parse(JSON.stringify(this.dataList));
			},
			// 删除当前确定选中的某一项
			deleteCurrentChoseList(row, index) {
				row.currentChoseList.splice(index, 1);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.patient-analysis {
		position: relative;
		overflow: hidden;
		height: 100%;
		display: flex;

		/deep/.el-select {
			width: 350px !important;
		}

		.out-container {
			// margin-left: 100px;
			transition: all 0.5s;
			padding: 10px 15px;
			border: 1px solid #e3e3e3;
			height: 70px;
			width: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			border-top: none;

			&.marginLeft {
				margin-left: 100px;
			}

			&:first-child {
				border-top: 1px solid #e3e3e3;
			}

			.out-relationship-select {
				position: absolute;
				left: -90px;
				top: 50%;
				margin-top: 20px;
			}

			.out-container-body {
				display: flex;
				align-items: center;

				// justify-content: center;
				.add-item {
					color: #0099ff;
				}

				.delete-btn {
					font-size: 16px;
					color: #666 !important;
					position: absolute;
					right: 10px;
					padding-left: 10px;
					cursor: pointer;
				}
			}

			&.out-container__active {
				border: 1px solid #0099ff !important;
				border-right: none !important;
				background: #f7f9ff;
				position: relative;
			}
		}

		.out-container-line {
			position: relative;
			box-sizing: border-box;

			.out-container-cover {
				position: absolute;
				top: 50%;
				left: -60px;
				width: 5px;
				height: 1000px;
				background-color: #fff;
				margin-top: 1px;
			}

			&::before {
				content: "";
				position: absolute;
				left: -60px;
				top: 50%;
				bottom: -1000px;
				border-left: 1.5px solid #e0e0e0;
			}

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				left: -60px;
				width: 60px;
				border-bottom: 1.5px solid #e0e0e0;
				margin-top: -0.5px;
			}
		}

		.right {
			flex: 1;

			.condition-filter {
				height: 100%;

				.custom-analysis-filter {
					height: 100%;
					position: relative;
					overflow: hidden;
					background: #f6f9ff;
					border: 1px solid #0099ff;
					padding: 0 10px 30px 30px;

					.single-analysis {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 30px 10px 14px 0;
					}

					.out-container2 {
						//   width: 100%;
						background-color: #fff;
						padding: 10px 15px;
						border: 1px solid #e3e3e3;
						margin-top: 15px;

						.out-container-cover {
							position: absolute;
							top: 50%;
							left: -60px;
							width: 5px;
							height: 1000px;
							background-color: #f6f9ff;
							margin-top: 1px;
						}

						.in-relationship-select {
							position: absolute;
							left: -90px;
							top: 50%;
							margin-top: 10px;
						}

						.in-relationship-list {
							overflow: hidden;

							.in-container-wrapper {
								.in-container {
									background-color: #fff;
									padding: 10px 0;
									padding-left: 10px;
									position: relative;

									.in-container-item {
										display: flex;
										align-items: center;

										/deep/.item-con {
											margin-right: 10px;
											display: flex;
											align-content: center;

											// position: relative;
											.el-date-editor .el-range-separator {
												width: 40px !important;
											}

											.delete-btn {
												font-size: 16px;
												color: #666 !important;
												cursor: pointer;
												padding-left: 10px;
											}
										}
									}
								}

								.in-container-line {
									position: relative;
									margin-left: 100px;

									&::before {
										content: "";
										position: absolute;
										left: -60px;
										top: 50%;
										bottom: -1000px;
										border-left: 1.5px solid #d6d6d6;
									}

									&::after {
										content: "";
										position: absolute;
										top: 50%;
										left: -60px;
										width: 60px;
										border-bottom: 1.5px solid #d6d6d6;
										margin-top: -0.5px;
									}

									.in-container-cover {
										position: absolute;
										top: 50%;
										left: -60px;
										width: 5px;
										height: 1000px;
										background-color: #fff;
										margin-top: 1px;
									}
								}

								.add-btn {
									margin: 20px 60px 8px 60px;
									border: 1px dashed #0099ff;
									color: #0099ff;
									line-height: 35px;
									font-size: 14px;
									text-align: center;
									cursor: pointer;
									border-radius: 6px;
								}
							}
						}
					}

					.add-btn-type2 {
						margin: 15px 0;
						border: 1px dashed #d6d6d6;
						color: #999;
						line-height: 60px;
						font-size: 14px;
						text-align: center;
						cursor: pointer;
						border-radius: 6px;
						background-color: #fff;
					}
				}
			}
		}
	}

	.btn-group {
		padding: 30px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.compare-select {
		display: flex;
		align-items: center;
	}

	.delete-btn {
		cursor: pointer;
		color: #0099ff;
	}

	.detail-info {
		.el-form-item {
			margin-bottom: 0;
		}
	}

	.paddLeft {
		padding-left: 200px;

		&.paddLeft2 {
			padding-left: 300px;
		}
	}

	.width20 {
		width: 20%;
	}

	.width30 {
		width: 30%;
	}

	.width100 {
		width: 100%;
	}

	.con-title {
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px solid #e9e9e9;
		padding-bottom: 24px;
		margin-bottom: 24px;
	}

	.search-box {
		.inline {
			display: flex;

			.el-form-item {
				flex: 1;

				.divider-line {
					width: 20px;
					height: 1px;
					background: #999;
					display: inline-block;
					margin: 0 10px;
				}
			}
		}
	}

	.export-string {
		display: flex;
		align-items: center;
	}

	.choose-list {
		overflow: hidden;
		max-width: 70%;

		.list-item {
			float: left;
			max-width: 142px;
			height: 30px;
			background: #f3faff;
			border-radius: 4px;
			border: 1px solid #009cff;
			margin-right: 16px;
			color: #009cff;
			padding: 0 34px 0 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin-bottom: 5px;

			.con-box {
				width: 100%;
			}

			.delete-btn {
				width: 10px;
				height: 10px;
				position: absolute;
				display: inline-block;
				right: 10px;
				top: 9px;
				background: url("../../assets/img/delete-btn2.png") center center;
				background-size: 100% 100%;
			}
		}
	}
</style>